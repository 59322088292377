export default {
    version: {
        num: '1.0.0.221115',
        //第一位：主版本号。当功能模块有较大的变动，比如增加多个模块或者整体架构发生变化。此版本号由项目决定是否修改。
        //第二位：子版本号。当功能有一定的增加或变化，比如增加了对权限控制、增加自定义视图等功能。此版本号由项目决定是否修改。
        //第三位：修订版本号。一般是 Bug 修复或是一些小的变动，要经常发布修订版，时间间隔不限，修复一个严重的bug即可发布一个修订版。此版本号由项目经理决定是否修改。
        //日期版本号（221112）：用于记录修改项目的当前日期，每天对项目的修改都需要更改日期版本号。此版本号由开发人员决定是否修改。
        stage: 'RC'//Alpha 内部测试版，Beta 测试版，RC 正式版
    },

}